import { Component, HostBinding } from '@angular/core';
import { LoadingService } from '../loading.service';

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
    constructor(public loading: LoadingService) {}

    @HostBinding('class.loading')
    public get isLoading(): boolean {
        return this.loading.isLoading;
    }
}
