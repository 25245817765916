<mat-card class="available" data-cy="select-side-by-side">
    <h4>Verfügbare Optionen</h4>
    <mat-selection-list (selectionChange)="listSelectionChange($event)">
        <cdk-virtual-scroll-viewport itemSize="52">
            <mat-list-option
                style="height: 52px"
                *cdkVirtualFor="let item of dataSource"
                [value]="item"
                [selected]="isSelected(item)"
            >
                <ng-container *ngIf="item">
                    <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: item }"></ng-container>
                </ng-container>
                <div class="loading" *ngIf="!item">
                    <div class="placeholder"></div>
                </div>
            </mat-list-option>
        </cdk-virtual-scroll-viewport>
    </mat-selection-list>
</mat-card>
<mat-icon>keyboard_arrow_right</mat-icon>
<mat-card class="selected">
    <h4>Ausgewählte Optionen</h4>
    <mat-selection-list class="selected-options" (selectionChange)="listSelectionChange($event)">
        <mat-list-option *ngFor="let item of selection.selected" [value]="item" [selected]="isSelected(item)">
            <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: item }"></ng-container>
        </mat-list-option>
    </mat-selection-list>
</mat-card>
