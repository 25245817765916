import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateAuthenticated, CanActivateIsAdmin, CanActivateNotAuthenticated } from './authentication.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { ShellComponent } from './shell/shell.component';

const routes: Routes = [
    {
        path: 'login',
        canActivate: [CanActivateNotAuthenticated],
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: '',
        component: ShellComponent,
        canActivate: [CanActivateAuthenticated],
        children: [
            {
                path: 'materials',
                loadChildren: () => import('./material-admin/material-admin.module').then(m => m.MaterialAdminModule),
            },
            {
                path: 'admin/groups',
                loadChildren: () => import('./admin/groups/groups.module').then(m => m.AdminGroupsModule),
                canActivate: [CanActivateIsAdmin],
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
            },
            {
                path: '',
                redirectTo: 'materials',
                pathMatch: 'full',
            },
            {
                path: 'not-found',
                component: NotFoundComponent,
            },
            {
                path: '**',
                redirectTo: '/not-found',
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
