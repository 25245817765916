import { Injectable } from '@angular/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    FormRulesGQL,
    MaterialGroupFieldRule,
    MaterialGroupPropertyTypeRule,
    PropertyType,
} from 'src/generated/graphql';

type MaterialGroupConfig = {
    fieldRules?: Maybe<Array<Maybe<Pick<MaterialGroupFieldRule, 'field' | 'rule'>>>>;
    propertyTypeRules?: Maybe<
        Array<Maybe<Pick<MaterialGroupPropertyTypeRule, 'rule'> & { propertyType?: Maybe<Pick<PropertyType, 'id'>> }>>
    >;
    parent?: Maybe<MaterialGroupConfig>;
};

type PropertyTypeInfo = Pick<PropertyType, 'id' | 'defaultFormRule'>;

export enum FORM_FILED_RULE {
    ENABLED = 'enabled',
    HIDDEN = 'hidden',
}

interface FormFieldRuleInterface {
    materialGroupId: string;
    rule: Observable<FORM_FILED_RULE>;
}

@Injectable({
    providedIn: 'root',
})
export class FieldRulesService {
    private formFieldRules = new Map<string, FormFieldRuleInterface[]>();

    constructor(private formRulesGql: FormRulesGQL) {}

    public getFieldState(field: string, materialGroup: string | null): Observable<FORM_FILED_RULE> {
        if (materialGroup === null) {
            return of(this.getDefaultFieldState(field));
        }
        if (!this.formFieldRules.has(field)) {
            const res: FormFieldRuleInterface = {
                materialGroupId: materialGroup,
                rule: this.resolveFormRulesFromCategory(field, materialGroup),
            };
            this.formFieldRules.set(field, [res]);
            return res.rule;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const cache = this.formFieldRules.get(field)!;
            const element = cache.find(rule => rule.materialGroupId === materialGroup);
            if (element) {
                return element.rule;
            } else {
                const res: FormFieldRuleInterface = {
                    materialGroupId: materialGroup,
                    rule: this.resolveFormRulesFromCategory(field, materialGroup),
                };

                cache.push(res);

                this.formFieldRules.set(field, cache);
                return res.rule;
            }
        }
    }

    public getPropertyTypeState(
        propertyType: PropertyTypeInfo,
        materialGroup: Observable<string | null>
    ): Observable<FORM_FILED_RULE> {
        return materialGroup.pipe(
            switchMap(groupId => {
                if (groupId === null) {
                    return of((propertyType.defaultFormRule as FORM_FILED_RULE) || FORM_FILED_RULE.ENABLED);
                }
                return this.resolvePropertyRulesFromCategory(propertyType, groupId);
            })
        );
    }

    private getDefaultFieldState(field: string): FORM_FILED_RULE {
        switch (field) {
            case 'cultivation':
            case 'origin':
                return FORM_FILED_RULE.HIDDEN;
        }

        return FORM_FILED_RULE.ENABLED;
    }

    private resolveFormRulesFromCategory(field: string, materialGroup: string): Observable<FORM_FILED_RULE> {
        return this.formRulesGql
            .fetch({
                materialGroup,
            })
            .pipe(map(rules => this.applyFieldRulesRecursively(field, rules.data.materialGroup)));
    }

    private applyFieldRulesRecursively(field: string, groupData: Maybe<MaterialGroupConfig>): FORM_FILED_RULE {
        if (!groupData) {
            return this.getDefaultFieldState(field);
        }

        const rule = groupData.fieldRules?.find(ruleConfig => ruleConfig?.field === field);
        if (rule) {
            return rule.rule as FORM_FILED_RULE;
        }
        return this.applyFieldRulesRecursively(field, groupData.parent);
    }

    private resolvePropertyRulesFromCategory(
        propertyType: PropertyTypeInfo,
        materialGroup: string
    ): Observable<FORM_FILED_RULE> {
        return this.formRulesGql
            .fetch({
                materialGroup,
            })
            .pipe(map(rules => this.applyPropertyTypeRulesRecursively(propertyType, rules.data.materialGroup)));
    }

    private applyPropertyTypeRulesRecursively(
        propertyType: PropertyTypeInfo,
        groupData: Maybe<MaterialGroupConfig>
    ): FORM_FILED_RULE {
        if (!groupData) {
            return (propertyType.defaultFormRule as Maybe<FORM_FILED_RULE>) || FORM_FILED_RULE.ENABLED;
        }

        const rule = groupData.propertyTypeRules?.find(ruleConfig => ruleConfig?.propertyType?.id === propertyType.id);
        if (rule) {
            return rule.rule as FORM_FILED_RULE;
        }
        return this.applyPropertyTypeRulesRecursively(propertyType, groupData.parent);
    }
}
