<mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened>
        <mat-toolbar>
            <mat-icon>eco</mat-icon>
            <span>Materialdatenbank</span>
        </mat-toolbar>
        <mat-nav-list>
            <h4>Materialdatenbank</h4>
            <a mat-list-item routerLinkActive="active" routerLink="/materials"><mat-icon>eco</mat-icon> Materialien</a>
        </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content #matDrawerContent>
        <router-outlet (activate)="onActivate()"></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
