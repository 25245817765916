import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MarkdownService } from './markdown.service';

@Pipe({
    name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
    constructor(private markdown: MarkdownService, private sanitizer: DomSanitizer) {}

    public transform(value: string, inline = false): SafeHtml | undefined {
        if (typeof value === 'string') {
            const markup = inline ? this.markdown.renderInline(value) : this.markdown.render(value);
            const template = document.createElement('template');
            template.innerHTML = markup;
            const anchors = template.content.querySelectorAll('a');
            for (const anchor of Array.from(anchors)) {
                if (anchor.getAttribute('href')?.indexOf('#') === 0) {
                    anchor.setAttribute('href', location.pathname + anchor.getAttribute('href'));
                }
            }
            return this.sanitizer.bypassSecurityTrustHtml(template.innerHTML);
        }
    }
}
