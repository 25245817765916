import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'idFromIri',
})
export class IdFromIri implements PipeTransform {
    public transform(value: string): string {
        const splittedIri = value.split('/');

        return splittedIri?.[splittedIri.length - 1] || value;
    }
}
