import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable()
export class LoadingService {
    private trackedObservable: Observable<unknown>[] = [];
    private watches: boolean[] = [];

    public trackWatch<T, V>(watch: QueryRef<T, V>): QueryRef<T, V> {
        const watchIndex = watch.queryId;
        this.watches[watchIndex] = true;
        watch.valueChanges.subscribe({
            complete: () => (this.watches[watchIndex] = false),
            next: ({ loading }) => {
                this.watches[watchIndex] = loading;
            },
        });
        return watch;
    }

    public refetch<T, V>(watch: QueryRef<T, V>, variables?: V | undefined): Promise<ApolloQueryResult<T>> {
        this.watches[watch.queryId] = true;
        const refetch = watch.refetch(variables);
        refetch.then(({ loading }) => (this.watches[watch.queryId] = loading));
        return refetch;
    }

    public get isLoading(): boolean {
        return this.trackedObservable.length > 0 || this.watches.includes(true);
    }

    public track<T>(observable: Observable<T>): Observable<T> {
        observable = observable.pipe(share());
        this.trackedObservable.push(observable);
        observable.subscribe({
            complete: () => {
                const index = this.trackedObservable.indexOf(observable);
                this.trackedObservable.splice(index, 1);
            },
            error: () => {
                const index = this.trackedObservable.indexOf(observable);
                this.trackedObservable.splice(index, 1);
            },
        });
        return observable;
    }
}
