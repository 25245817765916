import { Component } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

@Component({
    selector: 'app-max-length-indicator',
    templateUrl: './max-length-indicator.component.html',
    styleUrls: ['./max-length-indicator.component.scss'],
})
export class MaxLengthIndicatorComponent {
    constructor(private formField: MatFormField) {}

    public get length(): number {
        if (typeof this.formField._control.value === 'string') {
            return this.formField._control.value.length;
        }
        return 0;
    }

    public get maxLength(): number {
        const container = this.formField._inputContainerRef.nativeElement;
        if (container instanceof HTMLElement) {
            const inputEl = container.querySelector('input') || container.querySelector('textarea');
            if (inputEl) {
                return inputEl.maxLength;
            }
        }
        return 0;
    }
}
