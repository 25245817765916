export const notNullOrUndefined = <T>(item: T | undefined | null): item is T => item !== undefined && item !== null;

export const compareNumbers = (a: number, b: number): -1 | 0 | 1 => {
    if (a === b) {
        return 0;
    } else {
        if (a < b) {
            return 1;
        } else {
            return -1;
        }
    }
};
