import { Injectable } from '@angular/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    Change as GQLChangeType,
    MaterialScope,
    Location,
    KeyFigureValue,
    Property,
    Color,
    MaterialImage,
    MaterialScopeNameGQL,
    ColorNameGQL,
    KeyFigureMetadataGQL,
    PropertyMetadataGQL,
    PropertyMetadataQuery,
} from 'src/generated/graphql';
import { Material } from './create-material.service';

type ScalarMaterialRelatedTypes = string | number | boolean | null | undefined;
type ManyToManyTypes = MaterialScope | Location | Property | Color | MaterialImage;
type OneToManyTypes = KeyFigureValue;

type RelationMaterialRelatedTypes = Array<ManyToManyTypes | OneToManyTypes>;
type MaterialRelatedTypes = ScalarMaterialRelatedTypes | RelationMaterialRelatedTypes;

type Change = Omit<GQLChangeType, 'id' | '_id'>;

export type MaterialState = Omit<Material, 'properties' | 'keyFigures' | '__typename'> & {
    properties?: Maybe<
        Maybe<{
            id: string;
        }>[]
    >;
    keyFigures?: Maybe<
        Maybe<
            Pick<KeyFigureValue, 'id' | 'lowerValue' | 'upperValue'> & {
                keyFigure: {
                    id: string;
                };
            }
        >[]
    >;
};

type LocalChange = Omit<Change, 'applied' | 'rejected' | 'revision'>;
interface RelationChangeValue {
    type: 'set' | 'unset';
    relation: string;
    value?: unknown;
}

export interface KeyFigureChangeValue extends RelationChangeValue {
    value?: {
        lowerValue: number;
        upperValue: number;
    };
}

export interface KeyFigureChangeMetadata {
    id: string;
    name: string;
    symbol: string;
    unit: string;
    type: string;
    category: {
        id: string;
        name: string;
    };
}
@Injectable({
    providedIn: 'root',
})
export class MaterialChangeService {
    constructor(
        private materialScopeNameQuery: MaterialScopeNameGQL,
        private colorNameQuery: ColorNameGQL,
        private keyFigureMetadataQuery: KeyFigureMetadataGQL,
        private propertyMetadata: PropertyMetadataGQL
    ) {}

    public getReadableFieldName(change: Pick<Change, 'field'>): string {
        const names = {
            name: 'Materialname',
            scientificName: 'Wissenschaftliche Bezeichnungen',
            additionalNames: 'Weitere Bezeichnungen',
            description: 'Beschreibung',
            category: 'Materialklassifikation',
            locations: 'Herkunft, Entstehung, Herstellung',
            scopes: 'Anwendungsbereich',
            additionalScopes: 'Anwendung, Ergänzung',
            cultivation: 'Wachstum / Kultivierung',
            origin: 'Entstehung',
            extraction: 'Gewinnung',
            production: 'Herstellung',
            specialProcessing: 'Weitere Verarbeitung',
            specialCoupling: 'Verbindungstechniken, Ergänzung',
            colors: 'Farbbereich',
            additionalColorInformation: 'Farbbesonderheiten',
            smell: 'Geruch',
            fireBehaviorAdditions: 'Brandverhalten, Ergänzung',
            durability: 'Dauerhaftigkeit',
            durabilityBKI: 'Lebensdauer nach BKI',
            durabilityBNB: 'Lebensdauer nach BNB',
            localEnvironmentRisk: 'Risiko für die lokale Umwelt',
            ecologicalBalanceReference: 'Ökobilanz, Bezug',
            ecologyAdditionalInformation: 'Anmerkungen zur Ökobilanz',
            recyclability: 'Kreislauffähigkeit',
            keyFigures: 'Kennzahlen',
            properties: 'Wertlisten',
            mechanic: 'Mechanik',
            thermodynamic: 'Thermodynamik',
            resistance: 'Beständigkeit',
            usageTechnology: 'Gebrauchstechnik',
            acoustic: 'Akustik',
            sponsorBUW: 'Sponsor BUW',
            sponsorKIT: 'Sponsor KIT',
            sponsorMSA: 'Sponsor MSA',
            signatureBUW: 'Signatur BUW',
            signatureKIT: 'Signatur KIT',
            signatureMSA: 'Signatur MSA',
            copyrightImages: 'Copyright Bilddateien',
            copyrightGraphics: 'Copyright der Grafiken',
        };
        if (names[change.field]) {
            return names[change.field];
        }

        return change.field;
    }

    public getRelationEntityName(change: Pick<Change, 'field' | 'value'>): Observable<string> {
        const changeData = this.parseArrayChangeAction(change);
        switch (change.field) {
            case 'scopes':
                return this.materialScopeNameQuery
                    .fetch({ id: changeData.relation })
                    .pipe(map(result => result.data?.materialScope?.name || ''));
            case 'colors':
                return this.colorNameQuery
                    .fetch({ id: changeData.relation })
                    .pipe(map(result => result.data?.color?.name || ''));
            default:
                return of('Unknown relation');
        }
    }

    public getPropertyMetadata(change: Pick<Change, 'value'>): Observable<PropertyMetadataQuery['property']> {
        const relationData = this.parseArrayChangeAction(change);
        return this.propertyMetadata.fetch({ id: relationData.relation }).pipe(map(data => data.data.property));
    }

    public getColorCode(change: Pick<Change, 'value'>): Observable<string> {
        const changeData = this.parseArrayChangeAction(change);
        return this.colorNameQuery
            .fetch({ id: changeData.relation })
            .pipe(map(result => result.data?.color?.color || ''));
    }

    public parseArrayChangeAction(change: Pick<Change, 'value'>): RelationChangeValue {
        if (!change.value) {
            throw new Error('Invalid change value');
        }
        return JSON.parse(change.value);
    }

    public generateChangeset(material: MaterialState, originalMaterial: MaterialState): LocalChange[] {
        const changes = new Array<LocalChange>();
        for (const key in material) {
            if (
                Object.prototype.hasOwnProperty.call(material, key) &&
                Object.prototype.hasOwnProperty.call(originalMaterial, key)
            ) {
                changes.push(...this.getFieldChanges(material[key], originalMaterial[key], key));
            }
        }
        return changes;
    }

    public getKeyFigureMetadata(change: Pick<Change, 'value'>): Observable<KeyFigureChangeMetadata> {
        const changeData = this.parseKeyFigureChange(change);
        return this.keyFigureMetadataQuery
            .fetch({
                id: changeData.relation,
            })
            .pipe(
                map(data => {
                    if (!data.data.keyFigure) {
                        throw new Error('Unable to fetch metadata');
                    }
                    return data.data.keyFigure;
                })
            );
    }

    public parseKeyFigureChange(change: Pick<Change, 'value'>): KeyFigureChangeValue {
        if (!change.value) {
            throw new Error('Invalid change value');
        }
        return JSON.parse(change.value);
    }

    private getFieldChanges(
        newValue: MaterialRelatedTypes,
        oldValue: MaterialRelatedTypes,
        field: string
    ): LocalChange[] {
        if (!this.wasChanged(newValue, oldValue)) {
            return [];
        }
        if (this.isScalar(newValue) && this.isScalar(oldValue)) {
            return [
                {
                    __typename: 'Change',
                    field,
                    value: newValue === null || newValue === undefined ? null : newValue.toString(),
                    originalValue: oldValue === null || oldValue === undefined ? null : oldValue.toString(),
                    preApplyValue: null,
                },
            ];
        } else if (!this.isScalar(newValue) && !this.isScalar(oldValue)) {
            return [
                ...[
                    ...this.getCreatedRelations(newValue, oldValue),
                    ...this.getChangedRelations(newValue, oldValue),
                ].map<LocalChange>(createdFigure => ({
                    __typename: 'Change',
                    field,
                    value: JSON.stringify(this.getSetRelationChangeValue(createdFigure)),
                })),
                ...this.getDeletedRelations(newValue, oldValue).map<LocalChange>(deletedFigure => ({
                    __typename: 'Change',
                    field,
                    value: JSON.stringify(this.getUnsetRelationChangeValue(deletedFigure)),
                })),
            ];
        }
        return [];
    }

    private getSetRelationChangeValue(value: RelationMaterialRelatedTypes[0]): RelationChangeValue {
        const data: RelationChangeValue = {
            type: 'set',
            relation: this.isKeyFigureValue(value) ? value.keyFigure.id : value.id,
        };
        if (this.isKeyFigureValue(value)) {
            data.value = {
                lowerValue: value.lowerValue,
                upperValue: value.upperValue,
            };
        }
        return data;
    }

    private getUnsetRelationChangeValue(value: RelationMaterialRelatedTypes[0]): RelationChangeValue {
        return {
            type: 'unset',
            relation: this.isKeyFigureValue(value) ? value.keyFigure.id : value.id,
        };
    }

    private wasChanged(newValue: MaterialRelatedTypes, oldValue: MaterialRelatedTypes): boolean {
        if (this.isScalar(newValue)) {
            return newValue !== oldValue;
        } else if (!this.isScalar(oldValue) && (newValue.length > 0 || oldValue.length > 0)) {
            if (this.isKeyFigureRelation(oldValue) && this.isKeyFigureRelation(newValue)) {
                return this.isKeyFigureRelationsChanged(newValue, oldValue);
            } else {
                // We only need to diff the related ids
                const newIds = new Set(this.getIds(newValue));
                const oldIds = new Set(this.getIds(oldValue));
                return newIds.size !== oldIds.size || new Set([...newIds, ...oldIds]).size !== newIds.size;
            }
        }
        console.error('Unable to detect if a change was made for this object');
        return false;
    }

    private isKeyFigureRelationsChanged(newValue: KeyFigureValue[], oldValue: KeyFigureValue[]): boolean {
        if (newValue.length !== oldValue.length) {
            return true;
        }
        return (
            this.getDeletedRelations(newValue, oldValue).length > 0 ||
            this.getCreatedRelations(newValue, oldValue).length > 0 ||
            this.getChangedRelations(newValue, oldValue).length > 0
        );
    }

    private getDeletedRelations<T extends ManyToManyTypes | OneToManyTypes>(newValue: T[], oldValue: T[]): Array<T> {
        const result = new Array<T>();
        for (const value of oldValue) {
            if (!newValue.some(item => this.isSameRelation(value, item))) {
                result.push(value);
            }
        }
        return result;
    }

    private getCreatedRelations<T extends ManyToManyTypes | OneToManyTypes>(newValue: T[], oldValue: T[]): T[] {
        const result = new Array<T>();
        for (const value of newValue) {
            if (!oldValue.some(item => this.isSameRelation(value, item))) {
                result.push(value);
            }
        }
        return result;
    }

    private isSameRelation(a: RelationMaterialRelatedTypes[0], b: RelationMaterialRelatedTypes[0]): boolean {
        if (this.isKeyFigureValue(a) && this.isKeyFigureValue(b)) {
            return a.keyFigure.id === b.keyFigure.id;
        } else {
            return a.id === b.id;
        }
    }

    private getChangedRelations(
        newValue: RelationMaterialRelatedTypes,
        oldValue: RelationMaterialRelatedTypes
    ): RelationMaterialRelatedTypes {
        if (!this.isKeyFigureRelation(newValue) || !this.isKeyFigureRelation(oldValue)) {
            return [];
        }
        const result = new Array<KeyFigureValue>();
        for (const newItem of newValue) {
            const oldItem = oldValue.find(item => item.keyFigure.id === newItem.keyFigure.id);
            if (oldItem && (newItem.lowerValue !== oldItem.lowerValue || newItem.upperValue !== oldItem.upperValue)) {
                result.push(newItem);
            }
        }
        return result;
    }

    private getIds(value: { id: string }[]): string[] {
        return value.map(item => item.id);
    }

    /**
     * Returning true for empty arrays as well because we always check new and old value which will always have the same type.
     * In case one array is empty we still need to find a working change config for the given dataset.
     */
    private isKeyFigureRelation(data: RelationMaterialRelatedTypes): data is Array<KeyFigureValue> {
        return data.length === 0 || data.some(item => this.isKeyFigureValue(item));
    }

    private isKeyFigureValue(data: unknown): data is KeyFigureValue {
        if (data === null) {
            return false;
        } else if (typeof data === 'object') {
            return !!data?.hasOwnProperty('keyFigure');
        }
        return false;
    }

    private isScalar(data: unknown): data is ScalarMaterialRelatedTypes {
        return ['string', 'number', 'boolean', 'undefined'].includes(typeof data) || data === null;
    }
}
