import { Component, ViewChild } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
    @ViewChild('matDrawerContent')
    private matDrawerContent: MatDrawerContent;

    public onActivate() {
        this.matDrawerContent?.getElementRef()?.nativeElement?.scrollTo({ top: 0 });
    }
}
