/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['./search-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SearchFieldComponent),
        },
    ],
})
export class SearchFieldComponent implements ControlValueAccessor {
    public formControl = new FormControl('');

    @Input()
    public placeholder = '';

    private onChange = (_: any) => {};
    private onTouched = () => {};

    public focus() {
        this.onTouched();
    }

    public emitSearch() {
        if (!this.formControl.disabled) {
            this.onChange(this.formControl.value);
        }
    }

    public resetSearch() {
        this.writeValue('');
        this.emitSearch();
    }

    public writeValue(value: any): void {
        this.formControl.setValue(value);
    }

    public registerOnChange(fn: (_: any) => any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }
}
