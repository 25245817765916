import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UploadMaterialImageGQL, UploadMaterialImageMutation } from 'src/generated/graphql';

type MaterialImage = NonNullable<NonNullable<UploadMaterialImageMutation['uploadMaterialImage']>['materialImage']>;
@Injectable({
    providedIn: 'root',
})
export class MaterialMediaService {
    constructor(private uploadImageGql: UploadMaterialImageGQL) {}

    public uploadImage(image: File, type: string): Observable<MaterialImage> {
        return this.uploadImageGql.mutate({ file: image, type }, { context: { useMultipart: true } }).pipe(
            map(result => {
                if (!result?.data?.uploadMaterialImage?.materialImage) {
                    throw new Error('Unable to upload image');
                }
                return result.data.uploadMaterialImage.materialImage;
            })
        );
    }
}
