import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Directive, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import SimpleMDE from 'simplemde';
import { MarkdownService } from './markdown.service';
import CodeMirror from 'codemirror';
@Directive({
    selector: 'textarea[appMarkdownEditor]',
    providers: [{ provide: MatFormFieldControl, useExisting: MarkdownEditorDirective }],
})
export class MarkdownEditorDirective
    implements AfterViewInit, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor
{
    private static nextId = 0;

    @HostBinding()
    public id = `example-tel-input-${MarkdownEditorDirective.nextId++}`;

    @Input()
    public maxLength: number | null = null;

    public stateChanges = new Subject<void>();
    public placeholder: string;
    public focused: boolean;
    public get empty() {
        return this.editor?.value.length === 0;
    }

    public get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    public required: boolean;
    public disabled: boolean;
    public errorState: boolean;
    public controlType?: string;
    public autofilled?: boolean;
    public userAriaDescribedBy?: string;

    public set value(value: string | null) {
        this.stateChanges.next();
        this._value = value;
    }

    public get value(): string | null {
        return this._value;
    }

    private editor: SimpleMDE;
    private _value: string | null;

    constructor(
        private editorEl: ElementRef,
        private markdown: MarkdownService,
        private fm: FocusMonitor,
        @Optional() @Self() public ngControl: NgControl
    ) {
        fm.monitor(editorEl.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    public setDescribedByIds(ids: string[]) {
        const controlElement = this.editorEl.nativeElement as HTMLTextAreaElement;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    public onContainerClick(): void {
        this.editor.codemirror.focus();
    }

    public ngAfterViewInit(): void {
        this.editor = new SimpleMDE({
            element: this.editorEl.nativeElement,
            hideIcons: ['fullscreen', 'side-by-side', 'image', 'guide'],
            spellChecker: false,
            toolbar: [
                'bold',
                'italic',
                'heading',
                '|',
                'quote',
                'unordered-list',
                'ordered-list',
                '|',
                'link',
                {
                    name: 'footnote',
                    action: editor => {
                        const cm = editor.codemirror;
                        const currentRefId = this.getFootnoteRefId(cm).toString();
                        const endPoint = cm.getCursor('end');
                        cm.setSelection(endPoint, endPoint);
                        cm.replaceSelection(`[^${currentRefId || '1'}]`);
                        const lineCount = cm.lineCount();
                        cm.setSelection(
                            {
                                line: lineCount,
                                ch: 0,
                            },
                            {
                                line: lineCount,
                                ch: 0,
                            }
                        );
                        cm.replaceSelection(`\n[^${currentRefId || '1'}]: `);
                    },
                    className: 'fa fa-tag',
                    title: 'Fußnote',
                },
                '|',
                'preview',
            ],
            status: [
                {
                    className: 'characterLimit',
                    defaultValue: el => {
                        if (this.maxLength) {
                            el.innerHTML = `0 / ${this.maxLength} Zeichen`;
                        } else {
                            el.innerHTML = '';
                        }
                    },
                    onUpdate: el => {
                        if (this.maxLength && this.editor) {
                            el.innerHTML = `${this.editor.value().length} / ${this.maxLength} Zeichen`;
                        } else {
                            el.innerHTML = '';
                        }
                    },
                },
            ],
            previewRender: plainText => this.markdown.render(plainText),
            placeholder: this.placeholder,
            initialValue: this.value || '',
        });
        this.editor.codemirror.on('change', this.changeHandler.bind(this));
        this.editor.codemirror.on('blur', this.blurHandler.bind(this));
        this.editor.codemirror.on('beforeChange', this.verifyMaxLength.bind(this));
    }

    public ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.editorEl.nativeElement);

        this.editor.codemirror.off('change', this.changeHandler);
        this.editor.codemirror.off('blur', this.blurHandler);
        this.editor.codemirror.off('beforeChange', this.verifyMaxLength);
    }

    public writeValue(data: unknown): void {
        if (typeof data === 'string') {
            this.value = data;
            if (this.editor) {
                this.editor.value(data);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnTouched(fn: any): void {
        this.blurFn = fn;
    }

    private isCancellableEvent(ev: CodeMirror.EditorChange): ev is CodeMirror.EditorChangeCancellable {
        const cEv = ev as CodeMirror.EditorChangeCancellable;
        return cEv.update !== undefined && cEv.cancel !== undefined;
    }

    private verifyMaxLength(cm: CodeMirror.Editor, change: CodeMirror.EditorChange) {
        if (this.maxLength && this.isCancellableEvent(change) && change.update) {
            const str = change.text.join('\n');
            const delta = str.length - (cm.indexFromPos(change.to) - cm.indexFromPos(change.from));
            const overflow = cm.getValue().length + delta - this.maxLength;
            if (delta <= 0) {
                return true;
            }
            if (overflow > 0) {
                change.update(change.from, change.to, str.substr(0, str.length - delta).split('\n'));
            }
        }
    }

    private changeFn?: (string) => void;
    private blurFn?: () => void;

    private changeHandler() {
        if (this.changeFn) {
            this.changeFn(this.editor.value());
        }
    }

    private blurHandler() {
        if (this.blurFn) {
            this.blurFn();
        }
    }

    /**
     * Checking existing footnote references ([^1]) in whole text from textarea with RegExp match
     * If existing convert references ([^1], [^2], ...) to numbers and increase for new footnote
     * If not existing, that mean currently footnote is first one in the text
     *
     * @param codemirror SimpleMDE.codemirror
     * @returns number which is referenceId for new footnote
     */
    private getFootnoteRefId(codemirror): number {
        const text: string = codemirror.getValue();

        const allRefIds = text.match(/\[\^\d+\]/g);
        if (Array.isArray(allRefIds)) {
            const convertedRefIds = [...new Set(allRefIds.map(refId => refId.slice(2, refId.length - 1)))]
                .map(refId => parseInt(refId, 10))
                .sort((a, b) => b - a);
            return convertedRefIds[0] + 1;
        } else {
            return 1;
        }
    }
}
