import { DataSource, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, Input, Output, TemplateRef } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Subject } from 'rxjs';
import { notNullOrUndefined } from '../type-utils';

@Component({
    selector: 'app-select-side-by-side',
    templateUrl: './select-side-by-side.component.html',
    styleUrls: ['./select-side-by-side.component.scss'],
})
export class SelectSideBySideComponent<T> {
    @Input()
    public dataSource: DataSource<T>;

    @Input()
    public rowTemplate: TemplateRef<unknown>;

    @Output()
    public selectionChange: Subject<SelectionChange<T>>;

    @Input()
    public set selectedOptions(selectedOptions: Iterable<T>) {
        this.select(...selectedOptions);
    }

    public readonly selection = new SelectionModel<T>(true);

    constructor() {
        this.selectionChange = this.selection.changed;
    }

    @Input()
    public equalFn: (a: T, b: T) => boolean = (a, b) => a === b;

    public listSelectionChange(event: MatSelectionListChange): void {
        const removed = event.options.filter(option => !option.selected).map(option => option.value);
        const added = event.options.filter(option => option.selected).map(option => option.value);

        this.select(...added);
        this.deselect(...removed);
    }

    public isSelected(item: T): boolean {
        return this.findSelectedObject(item) !== undefined;
    }

    private select(...values: T[]): void {
        this.selection.select(...values.filter(value => !this.isSelected(value)));
    }

    private deselect(...values: T[]): void {
        this.selection.deselect(...values.map(value => this.findSelectedObject(value)).filter(notNullOrUndefined));
    }

    private findSelectedObject(item: T): T | undefined {
        return this.selection.selected.find(selectedItem => this.equalFn(item, selectedItem));
    }
}
