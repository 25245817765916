import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getFootnoteValues } from './footnote.pipe';

@Pipe({
    name: 'footnotesHref',
})
export class FootnotesHref implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    public transform(text: string): SafeHtml | undefined {
        if (text) {
            let innerHTML = '';
            const finalFootnoteValues = getFootnoteValues(text);
            finalFootnoteValues.forEach((element, index, allFootNotes) => {
                innerHTML += this.renderHref(element.refId);
                if (index + 1 < allFootNotes.length) {
                    innerHTML += this.addComma();
                }
            });

            return this.sanitizer.bypassSecurityTrustHtml(innerHTML);
        }
    }

    private renderHref(refId: string) {
        return `<a href="${location.pathname}#fn${refId}" class="footnote-backref">${refId}</a>`;
    }

    private addComma() {
        return ', ';
    }
}
