import { Injectable } from '@angular/core';
import * as MarkdownIt from 'markdown-it';
import * as MarkdownItFootnotes from 'markdown-it-footnote';
import * as MarkdownItSub from 'markdown-it-sub';
import * as MarkdownItSup from 'markdown-it-sup';
import Renderer from 'markdown-it/lib/renderer';
import Token from 'markdown-it/lib/token';

@Injectable({
    providedIn: 'root',
})
export class MarkdownService {
    private markdown: MarkdownIt;
    public constructor() {
        this.markdown = new MarkdownIt({ breaks: true });
        this.markdown.use(MarkdownItFootnotes);
        this.markdown.use(MarkdownItSub);
        this.markdown.use(MarkdownItSup);

        this.markdown.renderer.rules.footnote_caption = (tokens: Token[], idx: number) => {
            let n = tokens[idx].meta.label;

            if (tokens[idx].meta.subId > 0) {
                n += ':' + tokens[idx].meta.subId;
            }
            return '[' + n + ']';
        };

        this.markdown.renderer.rules.footnote_anchor_name = (
            tokens: Token[],
            idx: number,
            options: MarkdownIt.Options,
            env
        ) => {
            const n = tokens[idx].meta.label;
            let prefix = '';

            if (typeof env.docId === 'string') {
                prefix = '-' + env.docId + '-';
            }

            return prefix + n;
        };

        this.markdown.renderer.rules.footnote_open = (
            tokens: Token[],
            idx: number,
            options: MarkdownIt.Options,
            env,
            self: Renderer
        ) => {
            let id = self.rules.footnote_anchor_name
                ? self.rules.footnote_anchor_name(tokens, idx, options, env, self)
                : '0';

            if (tokens[idx].meta.subId > 0) {
                id += ':' + tokens[idx].meta.subId;
            }

            const value = (tokens[idx].meta.label as string) || id;

            return '<li value="' + value + '" class="footnote-item">';
        };
    }

    public render(markdown: string): string {
        return this.markdown.render(markdown);
    }
    public renderInline(markdown: string): string {
        return this.markdown.renderInline(markdown);
    }
}
