import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import {
    CreateKeyFigureValueWithTempIdInput,
    CreateMaterialGQL,
    CreateRevisionGQL,
    MaterialApplicationsQuery,
    MaterialColorsQuery,
    MaterialGroupsQuery,
    MaterialLocationsQuery,
    MaterialPropertyTypesQuery,
    MaterialQuery,
    MaterialResolverDataFragment,
    UpdateKeyFigureValueInput,
    UpdateMaterialGQL,
} from 'src/generated/graphql';
import { FieldRulesService, FORM_FILED_RULE } from '../shared/field-rules.service';
import { notNullOrUndefined } from '../shared/type-utils';
import { MaterialChangeService, MaterialState } from './material-change.service';
import { MaterialKeyValue } from './material-form/material-properties/material-key-values/material-key-values.component';
import { MaterialMediaService } from './material-media.service';

export type FormMaterialGroup = NonNullable<NonNullable<MaterialGroupsQuery['materialGroups']>[0]>;
export type MaterialLocation = NonNullable<NonNullable<MaterialLocationsQuery['locations']>[0]>;
export type MaterialApplicationsQueryEdge = NonNullable<NonNullable<MaterialApplicationsQuery['materialScopes']>[0]>;
export type Material = NonNullable<MaterialQuery['material']>;
export type MaterialColor = NonNullable<NonNullable<MaterialColorsQuery['colors']>[0]>;
export type MaterialPropertyType = NonNullable<NonNullable<MaterialPropertyTypesQuery['propertyTypes']>[0]>;

/* ORDER of the fields matters */
export const referenceFootnoteFields = [
    'description',
    'cultivation',
    'origin',
    'extraction',
    'production',
    'specialProcessing',
    'specialCoupling',
    'mechanic',
    'thermodynamic',
    'resistance',
    'durability',
    'usageTechnology',
    'acoustic',
    'localEnvironmentRisk',
    'recyclability',
];

/* TODO: Switch once revisions should be stored */
const REVISION_MODE = false;
export interface MaterialImage {
    id?: string;
    url: string | SafeResourceUrl;
    dangling: boolean;
    captionForm: FormControl;
    type: string;
}
@Injectable()
export abstract class MaterialFormService implements OnDestroy {
    public readonly form: FormGroup;
    public keyFigureValues: MaterialKeyValue[] = [];
    public readonly selectedGroup$ = new BehaviorSubject<FormMaterialGroup | null>(null);
    public readonly selectedLocations$ = new BehaviorSubject<MaterialLocation[]>([]);
    public readonly selectedScopes$ = new BehaviorSubject<MaterialApplicationsQueryEdge[]>([]);
    public readonly selectedColors$ = new BehaviorSubject<MaterialColor[]>([]);
    public readonly keyFigureValues$ = new BehaviorSubject<MaterialKeyValue[]>([]);
    public materialImages = new Array<MaterialImage>();

    protected subscriptions = new Subscription();
    private selectedProperties = new SelectionModel<string>(true);

    constructor(
        private materialMedia: MaterialMediaService,
        private sanitizer: DomSanitizer,
        private fieldRuleService: FieldRulesService,
        formBuilder: FormBuilder
    ) {
        this.form = formBuilder.group({
            active: [true, Validators.required],
            name: ['', [Validators.required, Validators.maxLength(255)]],
            scientificName: ['', [Validators.maxLength(255)]],
            additionalNames: [''],
            category: [null, [Validators.required]],
            scopes: [[]],
            additionalScopes: [''],
            description: [''],
            locations: [[]],
            cultivation: [''],
            origin: [''],
            extraction: [''],
            production: [''],
            specialProcessing: [''],
            specialCoupling: [''],
            smell: [''],
            properties: [[]],
            colors: [[]],
            additionalColorInformation: [''],
            durability: [''],
            durabilityBKI: [''],
            durabilityBNB: [''],
            ecologyAdditionalInformation: [''],
            recyclability: [''],
            localEnvironmentRisk: [''],
            ecologicalBalanceReference: [''],
            fireBehaviorAdditions: [''],
            images: [[], this.allFilled],
            mechanic: [''],
            thermodynamic: [''],
            resistance: [''],
            usageTechnology: [''],
            acoustic: [''],
            sponsorBUW: ['', [Validators.maxLength(255)]],
            sponsorKIT: ['', [Validators.maxLength(255)]],
            sponsorMSA: ['', [Validators.maxLength(255)]],
            signatureBUW: ['', [Validators.maxLength(255)]],
            signatureKIT: ['', [Validators.maxLength(255)]],
            signatureMSA: ['', [Validators.maxLength(255)]],
            copyrightImages: ['', [Validators.maxLength(255)]],
            copyrightGraphics: ['', [Validators.maxLength(255)]],
        });

        setTimeout(() => {
            this.subscriptions.add(
                this.selectedProperties.changed.subscribe(event => {
                    this.form.controls.properties.patchValue(event.source.selected);
                })
            );
        }, 0);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public setGroup(group: FormMaterialGroup | null): void {
        if (group === null) {
            this.form.controls.category.patchValue(null);
            this.selectedGroup$.next(null);
        } else {
            this.form.controls.category.patchValue(group.id);
            this.selectedGroup$.next(group);
        }
    }

    public setLocations(locations: MaterialLocation[]): void {
        this.selectedLocations$.next(locations);
        this.form.controls.locations.patchValue(locations.map(location => location.id));
    }

    public setScopes(scopes: MaterialApplicationsQueryEdge[]): void {
        this.form.controls.scopes.patchValue(scopes.map(scope => scope.id));
        this.selectedScopes$.next(scopes);
    }

    public setKeyFigures(data: MaterialKeyValue[]);
    public setKeyFigures(category: string, data: MaterialKeyValue[]);
    public setKeyFigures(category: string | MaterialKeyValue[], data?: MaterialKeyValue[]): void {
        if (Array.isArray(category)) {
            this.keyFigureValues = category;
            this.keyFigureValues$.next(this.keyFigureValues);
            return;
        }
        this.keyFigureValues = [
            ...this.keyFigureValues.filter(value => value.keyFigure.category.formArea !== category),
            ...(data || []),
        ];
        this.keyFigureValues$.next(this.keyFigureValues);
    }

    public relayPropertyChange(change: SelectionChange<string>): void {
        this.selectedProperties.select(...change.added);
        this.selectedProperties.deselect(...change.removed);
    }

    public setColors(selected: MaterialColor[]): void {
        this.form.controls.colors.patchValue(selected.map(color => color.id));
        this.selectedColors$.next(selected);
    }

    public addImages(type: string, ...images: File[]): void {
        images.forEach(image => this.addImage(image, type));
    }

    public addImage(image: File, type: string): void {
        const imageObj: MaterialImage = {
            id: undefined,
            type,
            url: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(image)),
            dangling: true,
            captionForm: new FormControl(''),
        };

        this.materialImages.push(imageObj);
        this.materialMedia.uploadImage(image, type).subscribe({
            next: result => {
                imageObj.id = result.id;
                this.updateImageForm();
            },
            error: () => {
                this.materialImages.splice(this.materialImages.indexOf(imageObj), 1);
                this.updateImageForm();
            },
        });
        this.updateImageForm();
    }

    public removeImage(imageId: string): void {
        this.materialImages.splice(
            this.materialImages.findIndex(image => image.id === imageId),
            1
        );
        this.updateImageForm();
    }

    public getFormRule(field: string, categoryId: string): Observable<FORM_FILED_RULE> {
        return this.fieldRuleService.getFieldState(field, categoryId);
    }

    public getPropertyTypeRule(propertyType: MaterialPropertyType): Observable<FORM_FILED_RULE> {
        const categoryValue = this.form.controls.category.valueChanges.pipe(
            startWith(this.form.controls.category.value)
        ) as Observable<string>;
        return this.fieldRuleService.getPropertyTypeState(propertyType, categoryValue);
    }

    protected updateImageForm(): void {
        this.form.patchValue({
            images: this.materialImages.map(image => image.id),
        });
    }

    private allFilled(control: FormControl): ValidationErrors | null {
        if (!Array.isArray(control.value)) {
            return {
                noArrayValue: true,
            };
        }
        if (control.value.some(val => !val)) {
            return {
                allFilled: false,
            };
        }
        return null;
    }

    public abstract save(): Observable<MaterialResolverDataFragment>;
}

@Injectable()
export class CreateMaterialService extends MaterialFormService {
    constructor(
        formBuilder: FormBuilder,
        materialMedia: MaterialMediaService,
        domSanitizer: DomSanitizer,
        fieldRuleService: FieldRulesService,
        private createMaterialMutation: CreateMaterialGQL,
        private apollo: Apollo
    ) {
        super(materialMedia, domSanitizer, fieldRuleService, formBuilder);
    }

    public save(): Observable<MaterialResolverDataFragment> {
        this.preorderReferenceInMarkdownFields();
        return this.createMaterialMutation
            .mutate({
                material: { ...this.form.value, sort: 0, tmpId: 'newMaterialTmpId' },
                newKeyFigures: this.getNewKeyFigures(),
                updatedImages: this.materialImages.map(image => ({
                    id: image.id || '', // Ignore (won't happen as the images have been created already)
                    caption: image.captionForm.value,
                })),
            })
            .pipe(
                map(data => {
                    if (!data?.data?.createMultipleMaterials[0]) {
                        throw new Error('Unable to retrieve stored data');
                    }
                    return data?.data?.createMultipleMaterials[0];
                }),
                tap(() => {
                    this.apollo.client.cache.reset();
                })
            );
    }

    private getNewKeyFigures(): CreateKeyFigureValueWithTempIdInput[] {
        const newObjects = this.keyFigureValues.filter(obj => !obj.id);
        return newObjects.map(data => ({
            ...data,
            id: undefined,
            keyFigure: data.keyFigure.id,
            material: 'newMaterialTmpId',
        }));
    }

    private async preorderReferenceInMarkdownFields() {
        let newReferenceId = 1;

        for (const iterator of referenceFootnoteFields) {
            const control = this.form.get(iterator);
            if (control) {
                const allReferenceIds = (control.value as string).match(/\[\^\d+\]/g);
                if (Array.isArray(allReferenceIds)) {
                    let value = control.value as string;
                    for (const iterator of new Set(allReferenceIds)) {
                        if (iterator !== `[^${newReferenceId.toString()}]` && !iterator.includes('*')) {
                            value = value.split(iterator).join(`[^${newReferenceId.toString()}*)`);
                        }
                        newReferenceId = newReferenceId + 1;
                    }
                    value = value.replace(/\*\)/g, ']');
                    control.patchValue(value);
                }
            }
        }
    }
}

@Injectable()
export class EditMaterialService extends MaterialFormService implements OnDestroy {
    private materialId: string;
    private originalKeyFigureValues: MaterialKeyValue[];
    private originalMaterial: MaterialResolverDataFragment;

    /** Returns a material representation that can be used to calculate a changeset. Does not include metadata that is used for the UI, only data that need to be stored */
    private get materialState(): MaterialState {
        const material: MaterialState = {
            ...this.originalMaterial,
            ...(this.form.value as Material),
            category: {
                id: this.form.value.category,
                name: '',
            },
            scopes: (this.form.value.scopes as Array<string>).map(scope => ({
                id: scope,
                name: '',
            })),
            locations: (this.form.value.locations as Array<string>).map(location => ({
                id: location,
                name: '',
            })),
            properties: (this.form.value.properties as Array<string>).map(property => ({
                id: property,
            })),
            colors: (this.form.value.colors as Array<string>).map(color => ({
                id: color,
                name: '',
            })),
            images: this.materialImages.map(image => ({
                id: image.id || '',
                type: image.type,
                caption: image.captionForm.value,
            })),
            keyFigures: this.keyFigureValues.map(value => ({
                ...value,
                keyFigure: {
                    id: value.keyFigure.id,
                },
            })),
        };
        return material;
    }

    constructor(
        formBuilder: FormBuilder,
        materialMedia: MaterialMediaService,
        domSanitizer: DomSanitizer,
        fieldRuleService: FieldRulesService,
        private activatedRoute: ActivatedRoute,
        private createRevision: CreateRevisionGQL,
        private apollo: Apollo,
        private updateMaterialMutation: UpdateMaterialGQL,
        private changeService: MaterialChangeService
    ) {
        super(materialMedia, domSanitizer, fieldRuleService, formBuilder);

        this.subscriptions.add(
            this.activatedRoute.data.subscribe(data => {
                if (data.material) {
                    this.loadMaterialData(data.material as MaterialResolverDataFragment);
                }
            })
        );
    }

    public save(): Observable<MaterialResolverDataFragment> {
        this.preorderReferenceInMarkdownFields();
        if (REVISION_MODE) {
            return this.saveAsRevision();
        } else {
            return this.updateMaterial();
        }
    }

    private async preorderReferenceInMarkdownFields() {
        let newReferenceId = 1;

        for (const iterator of referenceFootnoteFields) {
            const control = this.form.get(iterator);
            if (control) {
                const allReferenceIds = (control.value as string).match(/\[\^\d+\](?!:)/g);
                if (Array.isArray(allReferenceIds)) {
                    let value = control.value as string;
                    for (const iterator of new Set(allReferenceIds)) {
                        if (iterator !== `[^${newReferenceId.toString()}]` && !iterator.includes('*')) {
                            value = value.split(iterator).join(`[^${newReferenceId.toString()}*)`);
                        }
                        newReferenceId = newReferenceId + 1;
                    }
                    value = value.replace(/\*\)/g, ']');
                    control.patchValue(value);
                }
            }
        }
    }

    private updateMaterial(): Observable<MaterialResolverDataFragment> {
        return this.updateMaterialMutation
            .mutate({
                material: { ...this.form.value, id: this.materialId },
                updatedKeyFigures: this.getUpdatedKeyFigures(),
                newKeyFigures: this.getNewKeyFigures(),
                deletedKeyFigures: this.getDeletedKeyFigureIds(),
                updatedImages: this.materialImages.map(image => ({
                    id: image.id || '', // Ignore (won't happen as the images have been created already)
                    caption: image.captionForm.value,
                })),
            })
            .pipe(
                map(data => {
                    if (!data?.data?.updateMaterial?.material) {
                        throw new Error('Failed to retrieve updated data');
                    }
                    return data.data.updateMaterial.material.id as any;
                }),
                tap(material => this.apollo.client.cache.evict({ id: 'Material:' + material.id }))
            );
    }

    private saveAsRevision(): Observable<MaterialResolverDataFragment> {
        if (!this.originalMaterial) {
            throw new Error('No original data exists yet');
        }
        const changeSet = this.changeService.generateChangeset(this.materialState, this.originalMaterial);
        return this.createRevision
            .mutate({
                description: 'Test',
                materialId: this.materialId,
                changes: changeSet.map(change => ({
                    revision: 'newRevision',
                    field: change.field,
                    value: change.value,
                })),
            })
            .pipe(map(() => this.originalMaterial));
    }

    private loadMaterialData(material: MaterialResolverDataFragment): void {
        this.originalMaterial = material;
        this.form.patchValue({
            ...material,
            properties: material?.properties?.filter(notNullOrUndefined).map(property => property.id),
        });
        this.setGroup(material.category);
        this.setLocations(material.locations?.filter(notNullOrUndefined) || []);
        this.setScopes(material.scopes?.filter(notNullOrUndefined) || []);
        this.setColors(material.colors?.filter(notNullOrUndefined) || []);
        this.setKeyFigures(material.keyFigures?.filter(notNullOrUndefined) || []);
        this.setImages(material.images || []);
        this.originalKeyFigureValues = material.keyFigures?.filter(notNullOrUndefined) || [];
        this.materialId = material.id;
    }

    private setImages(images: Material['images']): void {
        this.materialImages =
            images?.filter(notNullOrUndefined).map(image => ({
                id: image.id,
                type: image.type,
                url: image.filePath?.small || '',
                dangling: false,
                captionForm: new FormControl(image.caption),
            })) || [];
        this.updateImageForm();
    }

    private getUpdatedKeyFigures(): UpdateKeyFigureValueInput[] {
        const existing = this.keyFigureValues.filter(obj => !!obj.id);
        const changed = existing.filter(obj => this.checkKeyFigureChanged(obj));
        return changed.map(data => ({
            ...data,
            keyFigure: data.keyFigure.id,
            material: this.materialId,
        }));
    }

    private getNewKeyFigures(): CreateKeyFigureValueWithTempIdInput[] {
        const newObjects = this.keyFigureValues.filter(obj => !obj.id);
        return newObjects.map(data => ({
            ...data,
            id: undefined,
            keyFigure: data.keyFigure.id,
            material: this.materialId,
        }));
    }

    private getDeletedKeyFigureIds(): string[] {
        const originallyExisting = this.originalKeyFigureValues.map(obj => obj.id);
        const stillExisting = this.keyFigureValues.map(obj => obj.id);
        return originallyExisting.filter(id => !stillExisting.includes(id));
    }

    private checkKeyFigureChanged(newData: MaterialKeyValue): boolean {
        const existingObj = this.originalKeyFigureValues.find(originalVal => originalVal.id === newData.id);
        if (!existingObj) {
            return true;
        }
        return existingObj.lowerValue !== newData.lowerValue || existingObj.upperValue !== newData.upperValue;
    }
}
