import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface SuccessDialogData {
    title: string;
    message: string;
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private dialog: MatDialog) {}

    public success(title, message) {
        this.dialog.open<SuccessComponent, SuccessDialogData, void>(SuccessComponent, {
            data: {
                title,
                message,
            },
        });
    }
}

@Component({
    template: `<h2 mat-dialog-title>{{ data.title }}</h2>
        <mat-dialog-content>
            <p>{{ data.message }}</p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Okay</button>
        </mat-dialog-actions>`,
})
export class SuccessComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: SuccessDialogData) {}
}
