import { Component, Input } from '@angular/core';
import { User } from 'src/generated/graphql';

@Component({
    selector: 'app-user-preview',
    templateUrl: './user-preview.component.html',
    styleUrls: ['./user-preview.component.scss'],
})
export class UserPreviewComponent {
    @Input()
    public user: Pick<User, 'username' | 'firstName' | 'lastName'>;
}
