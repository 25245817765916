import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CurrentUserGQL, CurrentUserQuery } from 'src/generated/graphql';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService implements HttpInterceptor {
    public authenticated$: Observable<boolean>;
    public currentUser$: Observable<CurrentUserQuery | null>;
    public isAdmin$: Observable<boolean>;
    private accessToken: string | null = null;
    private currentUserQueryRef: QueryRef<CurrentUserQuery>;

    constructor(private http: HttpClient, private currentUserQuery: CurrentUserGQL) {
        this.accessToken = localStorage.getItem('kitmdb.access_token');
        this.fetchUser();
    }

    public login({ username, password }: { username: string; password: string }): Observable<boolean> {
        const result = this.http.post<{ authenticated: boolean; token: string }>(
            [environment.apiUrl, 'api/login'].join('/'),
            {
                username,
                password,
            }
        );

        return result.pipe(
            tap(resultData => {
                if (resultData.authenticated) {
                    this.storeToken(resultData.token);
                }
            }),
            switchMap(resultData => {
                if (resultData.authenticated) {
                    return from(this.currentUserQueryRef.refetch()).pipe(map(data => !!data.data.meUser));
                }
                return of(false);
            })
        );
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.accessToken && request.url.startsWith(environment.apiUrl) && !request.url.endsWith('/api/login')) {
            request = request.clone({
                headers: request.headers.append('X-AUTH-TOKEN', this.accessToken),
            });
        }
        return next.handle(request);
    }

    public storeToken(token: string): void {
        localStorage.setItem('kitmdb.access_token', token);
        this.accessToken = token;
        this.currentUserQueryRef.refetch();
    }

    private fetchUser(): void {
        this.currentUserQueryRef = this.currentUserQuery.watch({}, { fetchPolicy: 'network-only' });
        this.currentUser$ = this.currentUserQueryRef.valueChanges.pipe(
            map(result => result.data),
            catchError(() => of(null))
        );
        this.authenticated$ = this.currentUser$.pipe(map(result => !!result?.meUser));
        this.isAdmin$ = this.currentUser$.pipe(
            map(result => [...result?.meUser?.roles].includes('ROLE_ADMIN') || false)
        );
    }
}
@Injectable({
    providedIn: 'root',
})
export class CanActivateAuthenticated implements CanActivate {
    constructor(private authService: AuthenticationService, private router: Router) {}
    public canActivate(): Observable<boolean | UrlTree> {
        return this.authService.authenticated$.pipe(map(result => result || this.router.parseUrl('/login')));
    }
}

@Injectable({
    providedIn: 'root',
})
export class CanActivateNotAuthenticated implements CanActivate {
    constructor(private authService: AuthenticationService, private router: Router) {}
    public canActivate(): Observable<boolean | UrlTree> {
        return this.authService.authenticated$.pipe(map(result => !result || this.router.parseUrl('/')));
    }
}

@Injectable({
    providedIn: 'root',
})
export class CanActivateIsAdmin implements CanActivate {
    constructor(private authService: AuthenticationService, private router: Router) {}
    public canActivate(): Observable<boolean | UrlTree> {
        return this.authService.isAdmin$;
    }
}
