import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MaterialQuery } from 'src/generated/graphql';
import { referenceFootnoteFields } from '../material-admin/create-material.service';

type Material = MaterialQuery['material'];

export function getFootnoteValues(text: string) {
    if (!text) {
        return [];
    }

    const referencesId = text.match(/\[\^\d+\]:/g);
    if (Array.isArray(referencesId)) {
        const ridOfBackslashN = (value: string) =>
            value.trim().slice(-2) === '\n' ? value.trim().slice(0, -2) : value.trim();

        let splittedText: string[] = [];
        const finalFootnoteValues: { refId: string; text: string; position: number }[] = [];
        for (let index = 0; index < referencesId.length; index++) {
            const element = referencesId[index];
            if (index === 0) {
                splittedText = text.split(element);
            } else {
                splittedText = splittedText[1].split(element);
                const refId = referencesId[index - 1].match(/\d+/g)?.[0] || '';
                finalFootnoteValues.push({
                    refId: refId,
                    text: ridOfBackslashN(splittedText[0]),
                    position: parseInt(refId),
                });
            }
        }
        const refId = referencesId[referencesId.length - 1].match(/\d+/g)?.[0] || '';
        finalFootnoteValues.push({
            refId: refId,
            text: ridOfBackslashN(splittedText[1]),
            position: parseInt(refId),
        });

        finalFootnoteValues.sort((a, b) => a.position - b.position);

        return finalFootnoteValues;
    } else {
        return [];
    }
}

@Pipe({
    name: 'overallFootnotes',
})
export class OverallFootnotes implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    public transform(material: Material): SafeHtml | undefined {
        if (material) {
            let innerHTML = '';
            innerHTML = this.renderOlOpen();
            for (const field of referenceFootnoteFields) {
                const text = material[field] as string;
                if (text) {
                    const finalFootnoteValues = getFootnoteValues(text);
                    for (const element of finalFootnoteValues) {
                        innerHTML += this.renderLiOpen(element.refId);
                        innerHTML += this.renderFootnoteTextAndHref(element.text, element.refId);
                        innerHTML += this.renderLiClose();
                    }
                }
            }
            innerHTML += this.renderOlClose();

            return this.sanitizer.bypassSecurityTrustHtml(innerHTML);
        }
    }

    private renderOlOpen() {
        return '<ol class="footnotes-list">\n';
    }

    private renderOlClose() {
        return '</ol>\n';
    }

    private renderLiOpen(refId: string) {
        return '<li value="' + refId + '" class="footnote-item">';
    }

    private renderLiClose() {
        return '</li>\n';
    }

    private renderFootnoteTextAndHref(text: string, refId: string) {
        return (
            '<p>' +
            text +
            '<a id="fn' +
            refId +
            '" href="' +
            location.pathname +
            '#fnref' +
            refId +
            '" class="footnote-backref">↩︎</a></p>'
        );
    }
}
