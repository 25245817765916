<mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Suche</mat-label>
    <input
        matInput
        [placeholder]="placeholder"
        [formControl]="formControl"
        (focus)="focus()"
        (keydown.enter)="emitSearch()"
    />
    <button matSuffix mat-button color="primary" (click)="emitSearch()"><mat-icon>search</mat-icon></button>
</mat-form-field>
